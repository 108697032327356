var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile update-password-container" },
    [
      _c("CategoryTitle", {
        staticClass: "px-3",
        attrs: { category: _vm.category }
      }),
      _c("UserPasswordAndPrivacy", {
        ref: "passwordDataForm",
        attrs: {
          userData: _vm.userData,
          isProfileForm: true,
          showOldPassword: true,
          showPrivacyFields: false,
          response: { status: 0 }
        },
        on: { submit: _vm.changePassword }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }